import { SelectProps } from 'antd/lib/select';
import { message, Select } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Option } from '../../../types';
import { getMessageInError } from '../../../hooks/fetch';
import { CommoditiesGetParams, useCommoditiesGet, useCommoditiesId } from '../../../hooks/commodities';
import NotFoundContent from '../NotFoundContent';

interface SelectCommodityMulti extends SelectProps<number[], Option> {
  activeOnly?: boolean;
}

const SelectCommodityMulti: React.FC<SelectCommodityMulti> = ({ activeOnly, ...props }): JSX.Element => {
  const commoditiesGet = useCommoditiesGet();
  const commoditiesId = useCommoditiesId();

  const [options, setOptions] = useState<Option[]>([]);
  const [commoditiesName, setCommoditiesName] = useState<string>('');

  const fetch = () => {
    const params: CommoditiesGetParams = { page: 1, limit: 999, orderBy: 'ASC', orderByColumn: 'name' };

    if (activeOnly) {
      params.status = true;
    }

    if (commoditiesName) {
      params.name = commoditiesName;
    }

    commoditiesGet.fetch(params);
  };

  useEffect(() => {
    if (commoditiesName && commoditiesName.length > 1) {
      const id = setTimeout(fetch, 700);

      return () => clearTimeout(id);
    }

    fetch();

    return undefined;
  }, [commoditiesName]);

  useEffect(() => {
    if (commoditiesGet.data?.items.length) {
      setOptions(commoditiesGet.data.items.map(({ name, id }): Option => ({ label: name, value: id })));
    }
  }, [commoditiesGet.data]);

  const ref = useRef<boolean>(true);

  useEffect(() => {
    if (options.length && props.value && ref.current) {
      ref.current = false;
      const current = props.value.filter((item) => !options.map(({ value }) => +value).includes(item));

      if (current.length && !commoditiesId.loading) {
        current.forEach((value) => {
          commoditiesId.fetch(undefined, `${value}`)
            .then((res) => setOptions([
              ...options,
              { label: res?.name || '', value },
            ]));
        });
      }
    }
  }, [options, props.value]);

  useEffect(() => {
    if (commoditiesGet.error) {
      message.error(getMessageInError(commoditiesGet.error));
      commoditiesGet.clearError();
    }
  }, [commoditiesGet.error]);

  const filterOption = useCallback((input: string, option: Option | undefined) => (
    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
  ), []);

  return (
    <Select
      mode="multiple"
      loading={commoditiesGet.loading || commoditiesId.loading}
      options={options}
      onSearch={(name) => setCommoditiesName(name)}
      allowClear
      showSearch
      placeholder="Please select"
      filterOption={filterOption}
      notFoundContent={<NotFoundContent message="Commodity not found." />}
      {...props}
    />
  );
};

SelectCommodityMulti.defaultProps = {
  activeOnly: true,
};

export default SelectCommodityMulti;
