import React from 'react';
import { Content } from 'antd/es/layout/layout';

import TableQcReports from './Table';

const QcReports: React.FC = (): JSX.Element => (
  <Content>
    <TableQcReports />
  </Content>
);

export default QcReports;
