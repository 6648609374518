import React from 'react';
import { Content } from 'antd/es/layout/layout';

import TableWarehouses from './Table';

const Warehouses: React.FC = (): JSX.Element => (
  <Content>
    <TableWarehouses />
  </Content>
);

export default Warehouses;
