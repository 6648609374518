import { PagingDataResponse } from '../types';
import {
  DefaultFetchError, FetchCreate,
  FetchGet, useFetchCreate,
  useFetchGet,
} from './fetch';
import { ReportImages } from '../context/qcReportUpdateTemplate';

interface GalleryGetParams {
  page?: number;
  limit?: number;

}

export function useGalleryGet<D = PagingDataResponse<ReportImages>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<DD, GalleryGetParams> {
  return useFetchGet<D, DefaultFetchError, unknown, DD>(
    'images/gallery',
    { decorateData, autoStart: false },
  );
}

interface UploadImagesToGallery {
  success: boolean;
}

export const useUploadImagesToGallery = (): FetchCreate<UploadImagesToGallery, DefaultFetchError, FormData> => (
  useFetchCreate('users/gallery-images')
);
