import { SelectProps } from 'antd/lib/select';
import { message, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import { Option } from '../../../types';
import { getMessageInError } from '../../../hooks/fetch';
import NotFoundContent from '../NotFoundContent';
import { UsersGetParams, useUserId, useUsersGet } from '../../../hooks/users';

interface SelectUser extends SelectProps<number | number[], Option> {
  activeOnly?: boolean;
}

const SelectUser: React.FC<SelectUser> = ({ activeOnly, ...props }): JSX.Element => {
  const usersGet = useUsersGet();
  const userId = useUserId();

  const [options, setOptions] = useState<Option[]>([]);
  const [usersName, setUsersName] = useState<string>('');

  const fetch = () => {
    const params: UsersGetParams = { page: 1, limit: 999, orderBy: 'ASC' };

    if (activeOnly) {
      params.status = activeOnly;
    }

    if (usersName) {
      params.name = usersName;
    }

    usersGet.fetch(params);
  };

  useEffect(() => {
    if (usersName && usersName.length > 1) {
      const id = setTimeout(fetch, 700);

      return () => clearTimeout(id);
    }

    fetch();

    return undefined;
  }, [usersName]);

  useEffect(() => {
    if (usersGet.data?.items.length) {
      setOptions(usersGet.data.items.map(({ login, id }): Option => ({ label: login, value: id })));
    }
  }, [usersGet.data]);

  useEffect(() => {
    if (options.length && props.value) {
      const current = options.find(({ value }) => value === props.value);

      if (!current) {
        userId.fetch(undefined, `${props.value}`)
          .then((res) => setOptions([
            ...options,
            { label: res?.login || '', value: props.value as number },
          ]));
      }
    }
  }, [options, props.value]);

  useEffect(() => {
    if (usersGet.error) {
      message.error(getMessageInError(usersGet.error));
      usersGet.clearError();
    }
  }, [usersGet.error]);

  const filterOption = useCallback((input: string, option: Option | undefined) => (
    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
  ), []);

  return (
    <Select
      loading={usersGet.loading || userId.loading}
      options={options}
      onSearch={(name) => setUsersName(name)}
      showSearch
      allowClear
      placeholder="Please select"
      filterOption={filterOption}
      notFoundContent={<NotFoundContent message="User not found." />}
      {...props}
    />
  );
};

SelectUser.defaultProps = {
  activeOnly: false,
};

export default SelectUser;
