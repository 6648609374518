import React, { useEffect } from 'react';
import { Card, Col, Form, Input, Row } from 'antd';

import { required } from '../../../../../utils/inputRules';
import SelectStatus from '../../../../Common/SelectStatus';
import { useContextWarehouseCreate } from '../../../../../context/warehouseCreate';

const GeneralInfo: React.FC = (): JSX.Element => {
  const [form] = Form.useForm();
  const { values, onSave, setValue } = useContextWarehouseCreate();

  useEffect(() => {
    if (form) {
      form.setFieldsValue({ status: true, ...values });
    }
  }, [form, values]);

  return (
    <Card title="General info" style={{ marginBottom: '24px' }}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onSave}
        className="warehouse_create"
      >
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item name="name" label="Name" rules={required}>
              <Input
                type="text"
                onChange={({ target: { value } }) => setValue({ name: value })}
                placeholder="Please enter"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="location" label="Location">
              <Input
                type="text"
                placeholder="Please enter"
                onChange={({ target: { value } }) => setValue({ location: value })}
              />
            </Form.Item>
          </Col>
          <Col span={8} />
          <Col span={8}>
            <Form.Item name="status" label="Status" rules={required}>
              <SelectStatus disabled />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default GeneralInfo;
