export enum LpReportTypeStatuses {
  active = 'Active',
  archived = 'Archived',
}

export enum ReportStatuses {
  expired = 'Expired',
  completed = 'Completed',
  'not started' = 'Not started',
  'in progress' = 'In progress',
}

export type ReportStatus = 'expired' | 'completed' | 'not started' | 'in progress';

export default LpReportTypeStatuses;
