import { FetchSuccess, PagingDataResponse } from '../types';
import {
  DefaultFetchError,
  FetchCreate,
  FetchGet,
  FetchGetId,
  FetchUpdate,
  useFetchCreate,
  useFetchGet,
  useFetchGetId,
  useFetchUpdate,
} from './fetch';

export interface LpReportType {
  id: string;
  icon: string;
  name: string;
  status: string;
}

export interface LpReportTypeCreateParams {
  name: string;
  icon: string;
  awType: string | '';
  awDocType: string | '';
}

export const useLpReportTypeCreate = (): FetchCreate<FetchSuccess, DefaultFetchError, LpReportTypeCreateParams> => (
  useFetchCreate('reports/types')
);

export interface LpReportTypesGetParams {
  name?: string;
  page?: number;
  limit?: number;
  search?: string;
  status?: 'active'| 'archived';
  orderBy?: 'ASC' | 'DESC';
  orderByColumn?: 'id' | 'name' | 'status';
}

export interface TableLpReportTypeRow {
  id: string;
  key: number;
  name: string;
  icon: string;
  status: string;
}

interface LpReportTypesTableData {
  total: number;
  lpReportTypes: TableLpReportTypeRow[];
}

export const useTableLpReportTypeRowsGet = (): FetchGet<LpReportTypesTableData, LpReportTypesGetParams> => (
  useLpReportTypesGet((data: PagingDataResponse<LpReportType>): LpReportTypesTableData => ({
    total: data.meta.totalItems,
    lpReportTypes: data.items.map((lpReportType: LpReportType): TableLpReportTypeRow => ({
      id: lpReportType.id.toString(),
      key: +lpReportType.id,
      name: lpReportType.name,
      icon: lpReportType.icon,
      status: lpReportType.status,
    })),
  }))
);

export function useLpReportTypesGet<D = PagingDataResponse<LpReportType>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<DD, LpReportTypesGetParams> {
  return useFetchGet<D, DefaultFetchError, unknown, DD>(
    'reports/types',
    { decorateData, autoStart: false },
  );
}

export const useLpReportTypeUsedIconsGet = (): FetchGet<string[]> => useFetchGet(
  'reports/types/used/icons',
  { autoStart: false },
);

export interface LpReportTypeUpdateParams {
  name: string;
  icon: string;
  status: 'active'| 'archived';
  awType: string | '';
  awDocType: string | '';
}

export const useLpReportTypeUpdate = (): FetchUpdate<FetchSuccess, DefaultFetchError, LpReportTypeUpdateParams> => (
  useFetchUpdate('reports/types')
);

interface LpReportTypeId extends LpReportType {
  created: string;
  updated: string;
}

export const useLpReportTypeId = (): FetchGetId<LpReportTypeId> => (
  useFetchGetId('reports/types', '', { autoStart: false })
);
