import { UserSimple } from './users';
import { FetchSuccess, PagingDataResponse } from '../types';
import {
  DefaultFetchError,
  FetchCreate,
  FetchGet,
  FetchGetId,
  FetchUpdate,
  useFetchCreate,
  useFetchGet,
  useFetchGetId,
  useFetchUpdate,
} from './fetch';

export interface WarehouseCreateParams {
  name: string;
  users: number[];
  location?: string;
}

export const useWarehouseCreate = (): FetchCreate<FetchSuccess, DefaultFetchError, WarehouseCreateParams> => (
  useFetchCreate('warehouses')
);

export interface WarehouseSimple {
  id: number;
  name: string;
  status: boolean;
}

export interface Warehouse extends WarehouseSimple {
  users: number[];
  location: string;
}

export interface WarehousesGetParams {
  name?: string;
  page?: number;
  limit?: number;
  users?: number;
  search?: string;
  status?: boolean;
  orderBy?: 'ASC' | 'DESC';
  location?: string;
  orderByColumn?: 'id' | 'name' | 'location' | 'status' | 'users';
}

export interface TableWarehouseRow extends WarehouseSimple {
  key: number;
  users: number;
  location: string;
}

interface WarehousesTableData {
  total: number;
  warehouses: TableWarehouseRow[];
}

export const useTableWarehouseRow = (): FetchGet<WarehousesTableData, WarehousesGetParams> => (
  useWarehousesGet((data: PagingDataResponse<Warehouse>): WarehousesTableData => ({
    total: data.meta.totalItems,
    warehouses: data.items.map((warehouses: Warehouse): TableWarehouseRow => ({
      id: warehouses.id,
      key: warehouses.id,
      name: warehouses.name,
      users: warehouses.users?.length || 0,
      status: warehouses.status,
      location: warehouses.location,
    })),
  }))
);

export function useWarehousesGet<D = PagingDataResponse<Warehouse>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<DD, WarehousesGetParams> {
  return useFetchGet<D, DefaultFetchError, unknown, DD>(
    'warehouses',
    { decorateData, autoStart: false },
  );
}

export interface WarehouseIdData extends WarehouseSimple {
  users: UserSimple[];
  location: string;
}

export const useWarehouseId = (): FetchGetId<WarehouseIdData> => useFetchGetId('warehouses', '', { autoStart: false });

export interface WarehouseUpdateParams {
  name: string;
  users: number[];
  status: boolean;
  location?: string;
}

export const useWarehouseUpdate = (): FetchUpdate<FetchSuccess, DefaultFetchError, WarehouseUpdateParams> => (
  useFetchUpdate('warehouses')
);
