import axios from 'axios';

export enum EnumRecordStatus {
  'ready'= 'ready',
  'in-progress' = 'in-progress',
  'completed' = 'completed'
}

export interface IAWStatus {
  status: keyof EnumRecordStatus;
}

export const getAWStatus = async (recordId: number, token: string) => {
  const res = axios.get<IAWStatus>(
    `${process.env.REACT_APP_API_URL}/records/${recordId}/aw-status`,
    {
      headers: {
        Authorization: `Bearer ${token || ''}`,
      },
    },
  ).then((response) => response.data);

  return res;
};

export const sendAWStatus = async (recordId: number, token: string) => {
  const res = axios.post<IAWStatus>(
    `${process.env.REACT_APP_API_URL}/records/${recordId}/complete`,
    undefined,
    {
      headers: {
        Authorization: `Bearer ${token || ''}`,
      },
    },
  ).then((response) => response.data);

  return res;
};
