import React from 'react';
import { Layout } from 'antd';

import Content from '../../../components/Pages/LpReports/Update';
import { displayName } from '../../../config';
import LpReportUpdateProvider from '../../../context/lpReportUpdate';

const UpdateTemplate: React.FC = (): JSX.Element => {
  document.title = `${displayName}: LP Report Edit Template`;

  return (
    <Layout>
      <LpReportUpdateProvider>
        <Content />
      </LpReportUpdateProvider>
    </Layout>
  );
};

export default UpdateTemplate;
