import { Card, List } from 'antd';
import React, { useEffect, useState } from 'react';

import { QcReportTemplate } from '../../../../../hooks/qcReports';
import { TemplateFieldTypes } from '../../../../../enums/templates';
import { TemplateFieldValue, useTemplatesId } from '../../../../../hooks/templates';

interface QualityReportPreview {
  template: QcReportTemplate;
}

const QualityReportPreview: React.FC<QualityReportPreview> = ({ template }): JSX.Element => {
  const templatesId = useTemplatesId();

  const [data, setData] = useState<{ id: number; name: string; required: boolean; value: string; }[]>([]);

  const getFieldValue = (field: TemplateFieldValue, value: string) => {
    switch (field.type) {
      case 'yes':
        return +value ? 'Yes' : 'No';
      case 'grading':
        return field.value.split(',')[+value] || '-';

      default:
        return value;
    }
  };

  const getFieldName = (type: TemplateFieldTypes, name: string, value: string) => {
    switch (type) {
      case 'yes':
        return `${name} (Yes, No)`;
      case 'grading':
        return `${name} (${value.split(',').join(', ')})`;
      case 'select':
        return `${name} (${value.split(',').join(', ')})`;

      default:
        return name;
    }
  };

  useEffect(() => {
    templatesId.fetch(undefined, template.template.id);
  }, [template.template.id]);

  useEffect(() => {
    if (templatesId.data && !templatesId.error) {
      setData(templatesId.data.templateFields
        .sort((prev, next) => prev.position < next.position ? -1 : 1)
        .map((field) => {
          const current = template.reportTemplateFields.find((templateField) => (
            templateField.templateField.id === field.id
          ));

          return ({
            id: current?.id || field.id,
            name: getFieldName(field.type, field.name, field.value),
            value: current ? getFieldValue(field, current.value) || '-' : '-',
            required: field.required,
          });
        }));
    }
  }, [templatesId.data]);

  return (
    <Card title="Quality report" loading={templatesId.loading}>
      <List
        className="quality-report-list"
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item) => (
          <List.Item key={item.id}>
            <div style={{ display: 'flex' }}>
              <div style={{ width: 370 }}>
                {item.required && <span style={{ color: 'var(--color-text-danger)', marginRight: 4 }}>*</span>}
                {item.name}
              </div>
              <div>{item.value}</div>
            </div>
          </List.Item>
        )}
      />
    </Card>
  );
};

export default QualityReportPreview;
