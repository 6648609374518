import { SelectProps } from 'antd/lib/select';
import { message, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import { Option } from '../../../types';
import { getMessageInError } from '../../../hooks/fetch';
import { useWarehouseId, useWarehousesGet, WarehousesGetParams } from '../../../hooks/warehouses';
import NotFoundContent from '../NotFoundContent';

interface SelectWarehouse extends SelectProps<number | number[], Option> {
  activeOnly?: boolean;
}

const SelectWarehouse: React.FC<SelectWarehouse> = ({ activeOnly, ...props }): JSX.Element => {
  const warehousesGet = useWarehousesGet();
  const warehouseId = useWarehouseId();

  const [options, setOptions] = useState<Option[]>([]);
  const [warehousesName, setWarehousesName] = useState<string>('');

  const fetch = () => {
    const params: WarehousesGetParams = { page: 1, limit: 999, orderBy: 'ASC', orderByColumn: 'name' };

    if (activeOnly) {
      params.status = activeOnly;
    }

    if (warehousesName) {
      params.name = warehousesName;
    }

    warehousesGet.fetch(params);
  };

  useEffect(() => {
    if (warehousesName && warehousesName.length > 1) {
      const id = setTimeout(fetch, 700);

      return () => clearTimeout(id);
    }

    fetch();

    return undefined;
  }, [warehousesName]);

  useEffect(() => {
    if (warehousesGet.data?.items.length) {
      setOptions(warehousesGet.data.items.map(({ name, id }): Option => ({ label: name, value: id })));
    }
  }, [warehousesGet.data]);

  useEffect(() => {
    if (options.length && props.value) {
      const current = options.find(({ value }) => value === props.value);

      if (!current) {
        warehouseId.fetch(undefined, `${props.value}`)
          .then((res) => setOptions([
            ...options,
            { label: res?.name || '', value: props.value as number },
          ]));
      }
    }
  }, [options, props.value]);

  useEffect(() => {
    if (warehousesGet.error) {
      message.error(getMessageInError(warehousesGet.error));
      warehousesGet.clearError();
    }
  }, [warehousesGet.error]);

  const filterOption = useCallback((input: string, option: Option | undefined) => (
    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
  ), []);

  return (
    <Select
      loading={warehousesGet.loading || warehouseId.loading}
      options={options}
      onSearch={(name) => setWarehousesName(name)}
      showSearch
      allowClear
      placeholder="Please select"
      filterOption={filterOption}
      notFoundContent={<NotFoundContent message="Warehouse not found." />}
      {...props}
    />
  );
};

SelectWarehouse.defaultProps = {
  activeOnly: true,
};

export default SelectWarehouse;
