import React from 'react';
import { Layout } from 'antd';

import Content from '../../../components/Pages/QcReports/UpdateTemplate';
import { displayName } from '../../../config';
import QcReportUpdateTemplateProvider from '../../../context/qcReportUpdateTemplate';

const UpdateTemplate: React.FC = (): JSX.Element => {
  document.title = `${displayName}: QC Report Edit Template`;

  return (
    <Layout>
      <QcReportUpdateTemplateProvider>
        <Content />
      </QcReportUpdateTemplateProvider>
    </Layout>
  );
};

export default UpdateTemplate;
