import React, { FC } from 'react';
import GalleryModal from '../../../Pages/QcReports/GalleryModal';
import { ReportImages } from '../../../../context/qcReportUpdateTemplate';

interface IGalleryTemplate {
  handleGalleryImages: (payload: { galleryImages: ReportImages[]; }) => void;
  handleVisibility: () => void;
  modalVisibility: boolean;
  selected: ReportImages[];
  title: string;
}

const GalleryTemplate: FC<IGalleryTemplate> = ({
  handleVisibility,
  modalVisibility,
  selected,
  handleGalleryImages,
  title,
}) => (
  <GalleryModal
    visible={modalVisibility}
    title={title}
    handleVisibility={handleVisibility}
    setValue={handleGalleryImages}
    checkedImages={selected || []}
  />
);

export default GalleryTemplate;
