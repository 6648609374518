import React from 'react';
import { Layout } from 'antd';

import Content from '../../../components/Pages/Templates/Create';
import { displayName } from '../../../config';
import TemplateCreateProvider from '../../../context/templateCreate';

const TemplateCreate: React.FC = (): JSX.Element => {
  document.title = `${displayName}: Template Create`;

  return (
    <Layout>
      <TemplateCreateProvider>
        <Content />
      </TemplateCreateProvider>
    </Layout>
  );
};

export default TemplateCreate;
