import React from 'react';
import { Select } from 'antd';

import { Option } from '../../../types';
import { TemplateFieldTypes, TemplateFieldTypesEnum } from '../../../enums/templates';

interface SelectQualityReportFieldType {
  onChange: (option: TemplateFieldTypes) => void;
  disabled?: boolean;
}

const SelectQualityReportFieldType: React.FC<SelectQualityReportFieldType> = ({ ...props }): JSX.Element => {
  const options: Option[] = Object
    .entries(TemplateFieldTypesEnum)
    .map(([value, label]): Option => ({ value, label }));

  return (
    <Select options={options} placeholder="Type" {...props} />
  );
};

SelectQualityReportFieldType.defaultProps = {
  disabled: false,
};

export default SelectQualityReportFieldType;
