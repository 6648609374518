import { Navigate, Routes } from 'react-router-dom';
import { Route as PublicRoute } from 'react-router';

import { useEffect, useState } from 'react';
import Auth from '../pages/auth';
import { routes } from '../components/Layout/Main/Sider/Menu';
import MainLayout from '../components/Layout/Main';
import { useAuth } from '../context/auth';
import { createChildrenRoutes } from './index';

const menuRoutes = createChildrenRoutes([
  ...routes,
]);

export default function Main(): JSX.Element {
  const { authorized, isRoleEnough, userRoleList } = useAuth();

  const [filteredMenuRoutes, setFilteredMenuRoutes] = useState(menuRoutes);

  // const filteredMenuRoutes = menuRoutes.filter((item) => isRoleEnough(item.roleNeeded || 'user'));

  useEffect(() => {
    if (userRoleList.length) {
      setFilteredMenuRoutes(menuRoutes.filter((item) => isRoleEnough(item.roleNeeded || 'user')));
    }
  }, [userRoleList]);

  return (
    <MainLayout>
      <Routes>
        {filteredMenuRoutes.map(({ privateRoute, bind: { element, ...bind } }) => (
          <PublicRoute
            key={`${bind.path}`}
            {...bind}
            element={(privateRoute && authorized) || !privateRoute ? element : <Auth />}
          />
        ))}
        <PublicRoute path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </MainLayout>
  );
}
