import { message } from 'antd';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import React, { createContext, FC, useContext, useEffect, useState } from 'react';

import { FetchError, FetchSuccess } from '../types';
import { FetchCreate, FetchGetId, getMessageInError } from '../hooks/fetch';
import {
  QcReportsId,
  QcReportTemplate,
  ReportGenerateParama,
  useQcReportsId,
  useReportGenerate,
} from '../hooks/qcReports';
import { useReportTemplateUpdate } from '../hooks/reportTemplate';

export type ISelectedImagesIdsOnEachStep = {
  [key: number]: number[];
};

interface QcReportsGenerateContext {
  onSave: () => void;
  values: QcReportTemplate[];
  setValue: (values: QcReportTemplate[]) => void;
  isDisabled: boolean;
  qcReportsId: FetchGetId<QcReportsId> | null;
  reportGenerate: FetchCreate<FetchSuccess, FetchError, ReportGenerateParama> | null;
  bolLogNumber: string;
  changeBolLogNumber: (value: string) => void;
  handeChecked: (imageId: number) => void;
  timestamp: boolean;
  handleTimestamp: (checked: boolean) => void;
  regenerate: boolean;
  initialSelectedIds: ISelectedImagesIdsOnEachStep;
}

const defaultValue: QcReportsGenerateContext = {
  onSave: () => undefined,
  values: [],
  setValue: () => undefined,
  isDisabled: false,
  qcReportsId: null,
  reportGenerate: null,
  bolLogNumber: '',
  changeBolLogNumber: () => undefined,
  handeChecked: () => undefined,
  timestamp: true,
  handleTimestamp: () => undefined,
  regenerate: false,
  initialSelectedIds: {},
};

export const QcReportsGenerateContext = createContext<QcReportsGenerateContext>(defaultValue);

interface IQcReportsGenerateProvider {
  children: React.ReactNode;
  regenerate?: boolean;
}

const QcReportsGenerateProvider: FC<IQcReportsGenerateProvider> = ({ children, regenerate }) => {
  const { id = '' } = useParams<{ id: string; }>();
  const navigate = useNavigate();
  const qcReportsId = useQcReportsId();
  const reportGenerate = useReportGenerate();

  const [searchParams] = useSearchParams();

  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [values, setValues] = useState<QcReportTemplate[]>([]);
  const [bolLogNumber, setBolLogNumber] = useState<string>('');
  const [reportImages, setReportImages] = useState<any[]>([]);
  const [timestamp, setTimestamp] = useState<boolean>(true);
  const reportTemplateUpdate = useReportTemplateUpdate();
  const [
    initialSelectedIds,
    setInitialSelectedIds,
  ] = useState<ISelectedImagesIdsOnEachStep>({});

  useEffect(() => {
    if (values.length && !Object.keys(initialSelectedIds).length) {
      const initialIds = values.reduce((acc, obj, currentIndex) => {
        acc[currentIndex] = obj.prevImages;

        return acc;
      }, {} as ISelectedImagesIdsOnEachStep);

      setInitialSelectedIds(initialIds);
    }
  }, [values]);

  const handleTimestamp = (checked: boolean) => {
    setTimestamp(checked);
  };

  const handeChecked = (imageId: number) => {
    setReportImages(reportImages.filter((el) => el !== imageId));
  };

  useEffect(() => {
    const currentStep = Number(searchParams.get('step')) || 0;

    if (regenerate) {
      const isInvalid = !!(values[currentStep] && values[currentStep].cutTemplateFields
        ?.filter((field) => field.required)
        .some((field) => !field.value)) || !(values[currentStep]?.prevImages?.length);

      return setIsDisabled(isInvalid);
    }

    const isValid = values.every((template) => (
      template?.reportTemplateFields
        .filter((field) => field.templateField.required)
        .every((field) => !!field.value)
      && template.images.length
    ));

    return setIsDisabled(values[currentStep]?.reportTemplateFields.length
      ? !isValid : !!(values[currentStep]?.images.length && searchParams.get('valid')));
  }, [values, searchParams.get('valid'), searchParams.get('step')]);

  const onSave = async () => {
    await onSaveTemplates();
    const bolLogSplit = bolLogNumber?.split('|')?.[0];

    const uniqueImagesIds = Array.from(
      new Set(values.map((el) => el.prevImages).flat()),
    );

    await reportGenerate.fetch({
      report: +id,
      images: regenerate ? uniqueImagesIds : (
        values.reduce((acc: number[], template) => (
          [...acc, ...template.images.map((image) => image.id)]
        ), [])
      ),
      addDataStump: timestamp,
      parentLogNumber: bolLogSplit || '',
    });
  };

  const onSaveTemplates = async () => {
    const promises = values.map(async (el) => {
      if (!el.cutTemplateFields) {
        return;
      }

      const formData = new FormData();

      formData.append(
        'reportTemplateFields',
        JSON.stringify(
          el.cutTemplateFields.filter((templateField) => (
            { id: templateField.id, value: templateField.value })),
        ),
      );

      await reportTemplateUpdate.fetch(formData, el.id);
    });

    await Promise.all(promises);
  };

  useEffect(() => {
    if (reportGenerate.data && !reportGenerate.error) {
      message.success('PDF successfully generated.');
      navigate(`/reports/qc/${id}`, { replace: true });
    }
  }, [reportGenerate.data]);

  useEffect(() => {
    if (reportGenerate.error) {
      message.error(getMessageInError(reportGenerate.error));
      reportGenerate.clearError();
    }
  }, [reportGenerate.error]);

  useEffect(() => {
    if (id) {
      qcReportsId.fetch(undefined, id);
    }
  }, [id]);

  useEffect(() => {
    if (qcReportsId.data && !qcReportsId.error) {
      setTimestamp(qcReportsId.data.addDataStump || qcReportsId.data.status === 'in progress');
      setValues(qcReportsId.data.reportTemplates);
    }
  }, [qcReportsId.data]);

  useEffect(() => {
    if (qcReportsId.error) {
      message.error(getMessageInError(qcReportsId.error));
      qcReportsId.clearError();
    }
  }, [qcReportsId.error]);

  useEffect(() => {
    if (values.length && !values.some((el) => el.cutTemplateFields)) {
      handleSetCutTemplateFields();
    }
  }, [values]);

  const handleSetCutTemplateFields = () => {
    const cutTemplateFields = values.map((el) => (
      el.reportTemplateFields.map(({ value, templateField }) => (
        { id: templateField.id, value, required: templateField.required }
      ))
    ));

    setValues(values.map((el, index) => ({ ...el, cutTemplateFields: cutTemplateFields[index] })));
  };

  return (
    <QcReportsGenerateContext.Provider
      value={{
        onSave,
        values,
        setValue: setValues,
        isDisabled,
        qcReportsId,
        reportGenerate,
        bolLogNumber,
        handeChecked,
        timestamp,
        handleTimestamp,
        changeBolLogNumber: setBolLogNumber,
        regenerate: regenerate || false,
        initialSelectedIds,
      }}
    >
      {children}
    </QcReportsGenerateContext.Provider>
  );
};

QcReportsGenerateProvider.defaultProps = {
  regenerate: false,
};

export default QcReportsGenerateProvider;

export const useContextQcReportGenerate = (): QcReportsGenerateContext => useContext(QcReportsGenerateContext);
