import clsx from 'clsx';
import React from 'react';
import { Spin } from 'antd';
import { SpinSize } from 'antd/lib/spin';

import styles from './index.module.less';

interface Loading {
  size?: SpinSize;
  absolute?: boolean;
  visible: boolean;
}

const Loading: React.FC<Loading> = ({
  size,
  absolute,
  visible,
}): JSX.Element | null => (
  visible ? (
    <div className={clsx(styles.loading, absolute ? styles.absolute : '')}>
      <Spin size={size} />
    </div>
  ) : null
);

export default Loading;

Loading.defaultProps = {
  size: 'large',
  absolute: false,
};
