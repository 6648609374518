import React from 'react';
import { SelectProps } from 'antd/lib/select';
import { Badge, Select } from 'antd';

import { Option } from '../../../types';

const SelectLpReportStatus: React.FC<SelectProps<boolean, Option>> = ({ ...props }): JSX.Element => (
  <Select {...props} />
);

SelectLpReportStatus.defaultProps = {
  options: [
    { label: <Badge status="success" text="Active" />, value: 'active' },
    { label: <Badge status="default" text="Archived" />, value: 'archived' },
  ],
};

export default SelectLpReportStatus;
