import React from 'react';
import { Layout } from 'antd';

import Content from '../../../components/Pages/QcReports/Profile';
import { displayName } from '../../../config';

const QcReportProfile: React.FC = (): JSX.Element => {
  document.title = `${displayName}: QC Report Profile`;

  return (
    <Layout>
      <Content />
    </Layout>
  );
};

export default QcReportProfile;
