export enum RecordsStatuses {
  // active = 'Active',
  expired = 'Expired',
  // archived = 'Archived',
  completed = 'Completed',
  notStarted = 'Not started',
  inProgress = 'In progress',
}

export enum RecordFilterStatuses {
  expired = 'Expired',
  completed = 'Completed',
  'not started' = 'Not started',
  'in progress' = 'In progress',
}

export default RecordsStatuses;
