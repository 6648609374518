import { FetchSuccess, PagingDataResponse } from '../types';
import {
  DefaultFetchError,
  FetchCreate,
  FetchGet,
  FetchGetId,
  FetchUpdate,
  useFetchCreate,
  useFetchGet,
  useFetchGetId,
  useFetchUpdate,
} from './fetch';

export interface CommoditySimple {
  id: number;
  name: string;
  status: boolean;
}

export interface Commodity extends CommoditySimple {
  created: string;
  updated: string;
  templateCount: string;
}

export interface CommoditiesGetParams {
  id?: number;
  name?: string;
  page?: number;
  limit?: number;
  search?: string;
  status?: boolean;
  orderBy?: 'ASC' | 'DESC';
  orderByColumn?: 'id' | 'name' | 'templateCount' | 'status';
}

export const useCommoditiesGet = (): FetchGet<PagingDataResponse<Commodity>, CommoditiesGetParams> => useFetchGet(
  'commodities',
  { autoStart: false },
);

export interface CommoditiesCreateParams {
  name: string;
}

export interface CommoditiesUpdateParams {
  name: string;
  status: boolean;
}

export const useCommoditiesCreate = (): FetchCreate<FetchSuccess, DefaultFetchError, CommoditiesCreateParams> => (
  useFetchCreate('commodities')
);

export const useCommoditiesUpdate = (): FetchUpdate<FetchSuccess, DefaultFetchError, CommoditiesUpdateParams> => (
  useFetchUpdate('commodities')
);

export const useCommoditiesId = (): FetchGetId<Commodity> => useFetchGetId('commodities', '', { autoStart: false });
