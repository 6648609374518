import { Content } from 'antd/es/layout/layout';
import React, { useCallback, useState } from 'react';

import TableCommodities from './Table';
import ModalCreateCommodity from './Modals/Create';
import ModalUpdateCommodity from './Modals/Update';
import { ModalState, ModalTypes } from '../../../types';

const Commodities: React.FC = (): JSX.Element => {
  const [modal, setModal] = useState<ModalState | null>(null);

  const openModal = useCallback((state: ModalState | null) => setModal(state), [setModal]);
  const closeModal = useCallback(() => setModal(null), [setModal]);

  return (
    <Content>
      <ModalCreateCommodity isOpen={modal?.type === ModalTypes.create} close={closeModal} />
      <ModalUpdateCommodity isOpen={modal?.type === ModalTypes.update} close={closeModal} id={modal?.id || 0} />
      <TableCommodities openModal={openModal} />
    </Content>
  );
};

export default Commodities;
