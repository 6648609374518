import { SelectProps } from 'antd/lib/select';
import { message, Select } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Option } from '../../../types';
import { getMessageInError } from '../../../hooks/fetch';
import { useRecordId, useRecordsGet, RecordsGetParams } from '../../../hooks/records';
import NotFoundContent from '../NotFoundContent';

interface SelectAwbRecordMulti extends SelectProps<number[], Option> {
  activeOnly?: boolean;
}

const SelectAwbRecordMulti: React.FC<SelectAwbRecordMulti> = ({ activeOnly, ...props }): JSX.Element => {
  const recordsGet = useRecordsGet();
  const recordId = useRecordId();

  const [options, setOptions] = useState<Option[]>([]);
  const [recordsName, setRecordsName] = useState<string>('');

  const fetch = () => {
    const params: RecordsGetParams = { page: 1, limit: 999, orderBy: 'ASC', type: 'awb', orderByColumn: 'bolAwb' };

    if (recordsName) {
      params.bolAwb = recordsName;
    }

    recordsGet.fetch(params);
  };

  useEffect(() => {
    if (recordsName && recordsName.length > 1) {
      const id = setTimeout(fetch, 700);

      return () => clearTimeout(id);
    }

    fetch();

    return undefined;
  }, [recordsName]);

  useEffect(() => {
    if (recordsGet.data?.items.length) {
      setOptions(recordsGet.data.items.map(({ bolAwb, id }): Option => ({ label: bolAwb, value: id })));
    }
  }, [recordsGet.data]);

  const ref = useRef<boolean>(true);

  useEffect(() => {
    if (options.length && props.value && ref.current) {
      ref.current = false;
      const current = props.value.filter((item) => !options.map(({ value }) => +value).includes(item));

      if (current.length && !recordId.loading) {
        current.forEach((value) => {
          recordId.fetch(undefined, `${value}`)
            .then((res) => setOptions([
              ...options,
              { label: res?.bolAwb || '', value: value as number },
            ]));
        });
      }
    }
  }, [options, props.value]);

  useEffect(() => {
    if (recordsGet.error) {
      message.error(getMessageInError(recordsGet.error));
      recordsGet.clearError();
    }
  }, [recordsGet.error]);

  const filterOption = useCallback((input: string, option: Option | undefined) => (
    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
  ), []);

  return (
    <Select
      mode="multiple"
      loading={recordsGet.loading || recordId.loading}
      options={options}
      onSearch={(name) => setRecordsName(name)}
      showSearch
      allowClear
      placeholder="Please select"
      filterOption={filterOption}
      notFoundContent={<NotFoundContent message="Record not found." />}
      {...props}
    />
  );
};

SelectAwbRecordMulti.defaultProps = {
  activeOnly: false,
};

export default SelectAwbRecordMulti;
