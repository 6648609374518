import { Button, Col, message, Row } from 'antd';
import React, { useEffect, useState } from 'react';

import { icons } from '../Icons/data';
import { getMessageInError } from '../../../../hooks/fetch';
import { useLpReportTypeUsedIconsGet } from '../../../../hooks/lpReportTypes';

interface SelectIcon {
  value: string;
  onChange?: (icon: string) => void;
  initialValue?: string;
}

const btnStyle = { display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'none' };

const SelectIcon: React.FC<SelectIcon> = ({ value, onChange = () => undefined, initialValue }): JSX.Element => {
  const iconsArray = Object.entries(icons);
  const lpReportTypeUsedIconsGet = useLpReportTypeUsedIconsGet();

  const [usedIcons, setUsedIcons] = useState<string[]>([]);

  useEffect(() => {
    lpReportTypeUsedIconsGet.fetch();
  }, []);

  useEffect(() => {
    if (lpReportTypeUsedIconsGet.data && !lpReportTypeUsedIconsGet.error) {
      setUsedIcons(lpReportTypeUsedIconsGet.data);
    }
  }, [lpReportTypeUsedIconsGet.data]);

  useEffect(() => {
    if (lpReportTypeUsedIconsGet.error) {
      message.error(getMessageInError(lpReportTypeUsedIconsGet.error));
      lpReportTypeUsedIconsGet.clearError();
    }
  }, [lpReportTypeUsedIconsGet.error]);

  return (
    <Row gutter={[2, 2]}>
      {iconsArray
        .sort(([key]) => usedIcons.includes(key) ? -1 : 1)
        .map(([key, Icon]) => (
          <Col key={key}>
            <Button
              type={value === key ? 'primary' : 'default'}
              icon={<Icon />}
              style={btnStyle}
              onClick={() => onChange(key)}
              disabled={usedIcons.includes(key) && key !== initialValue}
            />
          </Col>
        ))}
    </Row>
  );
};

SelectIcon.defaultProps = {
  onChange: () => undefined,
  initialValue: '',
};

export default SelectIcon;
