import React from 'react';
import { Layout } from 'antd';

import Content from '../../../components/Pages/Templates/Update';
import { displayName } from '../../../config';
import TemplatesUpdateProvider from '../../../context/templateUpdate';

const TemplateUpdate: React.FC = (): JSX.Element => {
  document.title = `${displayName}: Template Update`;

  return (
    <Layout>
      <TemplatesUpdateProvider>
        <Content />
      </TemplatesUpdateProvider>
    </Layout>
  );
};

export default TemplateUpdate;
