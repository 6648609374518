export enum TemplateStatuses {
  true = 'Active',
  false = 'Archived',
}

export type TemplateFieldTypes = 'grading' | 'text' | 'select' | 'yes';

export enum TemplateFieldTypesEnum {
  'grading' = 'Grading',
  'text' = 'Text',
  'select' = 'Select',
  'yes' = 'Yes/No',
}
